<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <template>
        <b-form @submit.prevent="handleSubmit">
          <b-card no-body>
            <b-card-header>
              <b-row>
                <b-col lg="8">
                  <h5 class="card-title">Schema Type</h5>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col lg="8">
                  <b-row>
                    <b-col lg="6">
                      <b-form-group>
                        <label>Schema Type<span class="text-danger mr5">*</span></label>
                        <v-select :options="['BlogPosting ','FAQ','Article','Review']" />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-button v-b-toggle.collapse-1 variant="success">Add Property</b-button>
                      <b-collapse id="collapse-1" class="mt-2">
                        <b-row class="mt-2">
                          <b-col lg="12">
                            <b-form-group label-for="articleContent">
                              <div>
                                <table class="table table-hover table-bordered">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b-form-group label-for="articleTitle">
                                          <v-select placeholer="Select Category" v-model="selected_property"
                                            :options="property" />
                                        </b-form-group>
                                      </td>
                                      <td>
                                        <b-form-group>
                                          <v-select :options="['BlogPosting ','FAQ','Article','Review']" />
                                        </b-form-group>
                                      </td>
                                      <td>
                                        <a v-b-tooltip.hover title="Delete Fitur" href="#" class="icon_action"><i
                                            class="ti-trash"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="add_cta">
                                  <p><a class="" href="#"><i class="fas fa-plus"></i> Add New</a></p>
                                </div>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-header>
              <b-row>
                <b-col lg="8">
                  <h5 class="card-title">Placement</h5>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col lg="12">
                  <b-row>
                    <b-col lg="3">
                      <b-form-group>
                        <v-select :options="['PostType','ShowGlobally']" />
                      </b-form-group>
                    </b-col>
                    <b-col lg="3">
                      <b-form-group>
                        <v-select :options="['Equal To','Not Equal To (Exclude)']" />
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group>
                        <v-select :options="['10 Cara Memilih Forex Brokers Trading Indonesia...']" />
                      </b-form-group>
                    </b-col>
                    <b-col lg="auto">
                      <b-button-group>
                        <b-button v-b-tooltip.hover title="Delete">AND</b-button>
                        <b-button v-b-tooltip.hover title="Delete"><i class="ti-trash"></i></b-button>
                      </b-button-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="12">
                  <b-button-group>
                    <b-button v-b-tooltip.hover title="Delete">OR</b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <b-row>
                <b-col lg="12" class="text-right">
                  <button type="submit" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
                  <button type="submit" class="btn  btn-rounded  btn-success">Submit</button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-form>
      </template>
    </b-container>
  </div>
</template>
<script>
  import GlobalVue from '@/libs/Global.vue'

  export default {
    name: 'Article',
    extends: GlobalVue,
    components: {},

    mounted() {
      this.loadSeoData()
      this.loadArticleData()
    },

    computed: {
      dataSrc() {
        return this.dummyData.article
      },
    },

    methods: {
      loadSeoData() {
        let datas = this.dataSrc.seo
        this.seoRow = datas
      },
      loadArticleData() {
        let datas = this.dataSrc.articleList
        datas.forEach(el => {
          this.articleData.push(el)
        })
      }
    },
    data() {
      return {
        selected: 'A',
        ads_device: [{
            item: 'A',
            name: 'Desktop'
          },
          {
            item: 'B',
            name: 'Mobile'
          }
        ],
        date: new Date(),
        days: [],
        featured: 'not_accepted',
        seoRow: {},
        articleRow: {},
        articleData: [],
        rows: 30,
        perPage: 1,
        currentPage: 5,
        selected_category: 'All Category',
        category: [
          'All Category',
          'Pinjaman Online',
          'Asuransi',
          'Bank Digital',
          'Investasi',
          'Rencana Keuangan',
        ],
        selected_sort: 'Sort By',
        sort: [
          'Sort By',
          'Most Viewed',
          'Least Viewed',
          'Oldest',
          'Newest',
        ],
        selected_property: 'Main Entity Of Page',
        property: [
          'Main Entity Of Page',
          'InLanguage',
          'Headline',
          'Description',
          'Article Body',
          'Keyword',
          'Name',
          'URL',
          'Image',
          'Date Published',
          'Date Modified',
        ],
        selected_parent: ' ---Select Parent Catgeory ---',
        parent: [
          ' ---Select Parent Catgeory ---',
          'Asuransi',
          'Advetorial',
          'Pengeluaran',
          'Saham'
        ],
        selected_status: 'Y',
        status: [{
            item: 'Y',
            name: 'Active'
          },
          {
            item: 'N',
            name: 'Inactive'
          }
        ],
      }
    },
    watch: {
      $route() {
        if (!this.isList) {
          if (!this.isAdd) {
            this.articleRow = this.articleData.find(el => el.id == this.$route.params.slug)
            console.log(this.articleRow)
          } else {
            this.articleRow = {}
          }
        }
      }
    }
  }
</script>